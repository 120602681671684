<template>
  <div>
   <div style="width: 100%; overflow-x: scroll;" class="checktable">
    <table class="dense_table checklist-table">
      <thead>
        <th id="" v-for="(grid, index) in gridLabels" :key="index">
          {{ grid.label}}
        </th>
      </thead>
      <tbody>
        <tr v-for="(row, rIndex) in gridValues" :key="rIndex">
          <td v-for="(grid, dIndex) in gridLabels" :key="dIndex" :style="dIndex === 0 ? 'padding:0px': 'padding:0px;border: none'">
            <template v-if="dIndex === 0">
              {{ row.name }}
            </template>
            <template v-else>
              <tr>
                <td class="pa-1">
                  <v-text-field dense flat v-model="row[`compValue_${dIndex}`]" solo hide-details></v-text-field>
                </td>
                <td class="pa-1">
                  <v-select dense flat hide-details :items="inventCompItem" v-model="row[`compSelect_${dIndex}`]"></v-select>
                </td>
              </tr>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
      </div>
  </div>
</template>
<script>
export default {
  props: {
    gridLabels: {
      type: Array,
      default: () => []
    },
    gridValues: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style>
.checklist-table tbody tr td {
  min-width: 60px;
  width: auto;
}
.checklist-table {
  white-space: nowrap;
  table-layout: auto;
}
.checktable td {
  border: 1px solid #0000001f;
  border-collapse: collapse;
}
</style>
